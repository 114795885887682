<template>
  <h1>Hello World !</h1>
</template>

<script>
export default {
  name: "HomePage"
}
</script>

<style scoped>

</style>